
// Chakra
import { useDisclosure, Spacer } from '@chakra-ui/react'

// C-CORE
import MenuMobile from '../components/MenuMobile'
import Header from '../components/Header'
import Logo from '../components/Logo'
import IdentityContainer from '../containers/IdentityContainer'
import HeaderMobile from '../components/HeaderMobile'



const HeaderContainer = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const menuItems = []

    
    return (
        <Header menuItems={menuItems}>
            <Logo />
            <Spacer />
            <IdentityContainer />
            <HeaderMobile onOpen={onOpen} title='s34d.io' />
            <MenuMobile
                onClose={onClose}
                isOpen={isOpen}
                menuItems={menuItems}
            />
        </Header>
    )
}

export default HeaderContainer
