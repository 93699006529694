import { Center, Link, Icon } from '@chakra-ui/react'
import { FaList } from 'react-icons/fa'
import { Link as RouterLink } from 'react-router-dom'

const ListDataMenuItem = ({ routerLocation = '/' }) => {
    return (
        <Center w='60px' h='60px'>
            <Link as={RouterLink} to='/'>
                <Icon as={FaList} w={4} h={4} color='white' />
            </Link>
        </Center>
    )
}

export default ListDataMenuItem
