import { useState } from 'react'

import { Box, IconButton, Spinner, Tooltip } from '@chakra-ui/react'
import { DownloadIcon } from '@chakra-ui/icons'
import { useFunctions } from 'reactfire'
import { httpsCallable } from "@firebase/functions"

const FileDownloadButtonContainer = ({
    project, 
    file,
    variant,
    color="blue.400",
    size="sm"
}) => {
    const functions = useFunctions()
    const getDownloadLink = httpsCallable(functions, 'files-getDownloadLink');

    const [isLoading, setIsLoading] = useState(false)

    const downloadFile = async (file, project) => {

        try {
            setIsLoading(true)
            // TODO NO_ID_FIELD = bad. give it an id in firestore.
            const link = await getDownloadLink({
                fileId: file.id,
                projectId: project?.NO_ID_FIELD
            })
            setIsLoading(false)
            window.location.href=link.data.url        
        } catch (error) {
            setIsLoading(false)
            // TODO toast error popup here.
        }
    }

    return (
        isLoading 
            ?   <Box width="25px">
                    <Spinner />
                </Box>
            :   <Tooltip label='Download File'>
                    <IconButton 
                        onClick={() => downloadFile(file, project)} 
                        variant={variant}
                        color={color} 
                        icon={<DownloadIcon />}
                        size={size}
                    />
                </Tooltip>     
    )
}

export default FileDownloadButtonContainer