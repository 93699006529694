import { useState, useEffect } from 'react'

// Chakra
import {
    useToast,
    Button
} from '@chakra-ui/react'

// Uppy
import Uppy from '@uppy/core'
import AwsS3Multipart from '@uppy/aws-s3-multipart'
import { DashboardModal, useUppy } from '@uppy/react'
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import { MdUpload } from 'react-icons/md'

const UploadComponent = ({
    onUploadComplete
}) => {

    const toast = useToast()
    
    const popToast = ({title, description, status}) => {
        toast({
            title: title,
            description: description,
            status: status,
            duration: 5000,
            isClosable: true
        })
    }

    const uppy = useUppy(() => {
        return new Uppy({
            restrictions: {
                maxNumberOfFiles: 5,
                allowedFileTypes: ['.tiff', '.tif', '.jp2'],
            },
            autoProceed: true,
            debug: false,
        }).use(AwsS3Multipart, {
            limit: 5,
            companionUrl: process.env.REACT_APP_COMPANION_URL,
        })
    })

    useEffect(() => {
        const handler = (file, event) => {
            onUploadComplete(file)
            popToast({
                title: `${file.name} uploaded`,
                status: 'success'
            })
        };
        uppy.on('upload-success', handler);
        
        uppy.on('restriction-failed', (file, error) => {
            popToast({
                title: `A max of 5 tiff & JP2 files may be uploaded`,
                status: 'error',
            })
        })

        return () => {
            uppy.off('upload-success', handler);
        };
        // eslint-disable-next-line
    }, [onUploadComplete]);
    

    const [open, setOpen] = useState(false)

    return (
        <>
            <Button
                variant="solid"
                colorScheme="blue"
                onClick={() => setOpen(true)}
                rightIcon={<MdUpload />}
            >
                Upload
            </Button>
            <DashboardModal
                uppy={uppy}
                closeModalOnClickOutside
                open={open}
                onRequestClose={() => setOpen(false)}
                proudlyDisplayPoweredByUppy={false}
            />
        </>
    )
}

export default UploadComponent
