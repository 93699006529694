import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    selectedTab: 0,
    searchIndex: 'c-core',
    activity: false,
}

function tabIndex (state, action) {
    const { payload } = action
    return {
        ...state,
        selectedTab: payload
    }
}

function activity (state, action) {
    const { payload } = action
    return {
        ...state,
        activity: payload
    }
}

const controls = createSlice({
    name: 'controls',
    initialState,
    reducers: {
        tabIndex,
        activity
    },
})

export default controls