import React from 'react'
import { useState } from 'react'

import UserListContainer from "./UserListContainer"
import UserInformationContainer from "./UserInformationContainer"

// React
import { useContext } from 'react'

// Chakra
import { Box, Flex } from '@chakra-ui/layout'
import { Button } from '@chakra-ui/button'
import { MdAdd } from 'react-icons/md'
import { useDisclosure } from '@chakra-ui/hooks'
import { Modal, ModalBody, ModalContent, ModalFooter, ModalOverlay } from '@chakra-ui/modal'

// Reactfire / Freibase
import { collection } from 'firebase/firestore'
import { useFirestore, useFirestoreCollectionData  } from 'reactfire'

// C-CORE
import { TenantContext }  from './TenantContainer'
import UserCreateContainer from './UserCreateContainer'
import UserDeleteBtnContainer from './UserDeleteBtnContainer'

const UserManagerContainer = () => {

    const [selectedUser, setSelectedUser] = useState()
    const orgId = useContext(TenantContext)
    const firestore = useFirestore()

    const userCollection = collection(firestore, `organisations/${orgId}/org_users`)
    const { data: users } = useFirestoreCollectionData(userCollection, {
        idField: 'id'
    })

    const { isOpen, onOpen, onClose } = useDisclosure()

    const handleSelectUser = (user) => {
        setSelectedUser(user)
    }

    return (
        <>
        {/* TODO: Text max widths on 2K+ screens */}
            <Flex 
                m={2}
                gap={2}
                >
                {/* Project Column */}
                <Box 
                    w={{sm: '100%', md: '60%'}} 
                    layerStyle="card.base"
                    p={2}
                >            
                    <Box align="right">
                        <Button
                             // temp due to no email template yet
                            onClick={onOpen} 
                            rightIcon={<MdAdd/>}
                            size="sm"
                            mb="2"
                        >
                            Add User
                        </Button>
                    </Box>
                    <UserListContainer users={users} handleSelectUser={handleSelectUser} selectedId={selectedUser?.id} />
                </Box>

                {/* User Info Column */}
                <Box 
                    w={{sm: '100%', md: '40%'}} 
                    layerStyle="card.base"
                    p={2}
                >
                    {selectedUser &&
                        <>
                            <Box align="right">
                                <UserDeleteBtnContainer userId={selectedUser.id} />
                            </Box>
                            <UserInformationContainer user={selectedUser} />
                        </>
                    }
                </Box>
            </Flex>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay/>
                <ModalContent>
                    <ModalBody>
                        <UserCreateContainer collection={userCollection}>
                            <Button mr={3} onClick={onClose} variant="outline">Close</Button>
                        </UserCreateContainer>
                    </ModalBody>
                    <ModalFooter>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default UserManagerContainer