import MenuLeft from '../components/MenuLeft'
import SettingsMenuItem from '../components/SettingsMenuItem'
import ListDataMenuItem from '../components/ListDataMenuItem'
// import ProjectsMenuItem from '../components/ProjectsMenuItem'
// import MapDataMenuItem from '../components/MapDataMenuItem'
// import UploadMenuItem from '../components/UploadMenuItem'

const MenuLeftContainer = () => {
    return (
        <MenuLeft toolbox={<SettingsMenuItem />}>
            <ListDataMenuItem />
            {/* <ProjectsMenuItem /> */}
            {/* <UploadMenuItem /> */}
            {/* <MapDataMenuItem /> */}
        </MenuLeft>
    )
}

export default MenuLeftContainer
