import { Flex, Box, Alert } from '@chakra-ui/react'
import { AlertIcon } from '@chakra-ui/react'

const FilesEmpty = () => {
    return (
        <Flex 
            width="Full" 
            align="center" 
            height="calc(100vh - 180px)"
            justifyContent="center" 
            >
                <Box maxWidth="500px">
                    <Alert status='warning'>
                        <AlertIcon />
                        You haven't uploaded anything yet!
                    </Alert>
                </Box>
        </Flex>
    )
}

export default FilesEmpty