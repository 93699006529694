import { Spinner as CHSpinner, Flex, Box } from '@chakra-ui/react'

const Spinner = () => {
    return (
        <Flex 
            width="Full" 
            align="center" 
            height="100vh"
            justifyContent="center" 
            >
                <Box maxWidth="300px">
                    <CHSpinner
                        thickness="4px"
                        speed="0.65s"
                        emptyColor="gray.200"
                        color="blue.500"
                        size="xl"
                        />
                </Box>
            </Flex>
    )
}

export default Spinner