import {
    Avatar,
    Center,
    MenuItem,
    MenuList,
    Menu,
    MenuButton,
    MenuGroup
} from '@chakra-ui/react'

/**
 * Component for interacting with user's identity
 */
const Identity = ({ 
    height = '65px', 
    p = '4', 
    name = null, 
    src = null,
    handleLogout,
    user
}) => {
    return (
        <>
            <Center
                w='75px'
                h={height}
                // mt="10px"
                display={{ base: 'none', md: 'inherit' }}
            >
                <Menu>
                    <MenuButton>
                        <Avatar />
                    </MenuButton>

                    <MenuList>
                        <MenuGroup title={user.email}>
                            <MenuItem onClick={handleLogout}>Logout</MenuItem>
                        </MenuGroup>
                    </MenuList>
                </Menu>
            </Center>
        </>
    )
}

export default Identity
