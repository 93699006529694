import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    selectedProject: null,
}

function selectProject (state, action) {
    const { payload } = action
    return {
        ...state,
        selectedProject: payload
    }
}

const project = createSlice({
    name: 'project',
    initialState,
    reducers: {
        selectProject,
    },
})

export default project