import { useEffect } from "react";
import { useForm } from "react-hook-form";

import { FormControl, FormLabel } from "@chakra-ui/form-control"
import { Input, Button, Box, Text, VStack } from "@chakra-ui/react"

const AdminCreateOrgansiationForm = ({handleCreate, children}) => {
    
    const { register, handleSubmit, formState: { errors, isSubmitSuccessful }, reset } = useForm();
    
    useEffect(() => {
        if (isSubmitSuccessful) {
        //   reset();
        }
      }, [isSubmitSuccessful, reset])

    return (
        <Box>
            <form onSubmit={handleSubmit(handleCreate)}>
                <VStack pt={4}>
                    <Text>Add a new Organisation to S34D.io</Text>
                    <FormControl id="name" isRequired>
                        <FormLabel>Company Name:</FormLabel>
                        <Input 
                            {...register("name", {
                                required: "Please enter a name of at least 5 characters",
                                minLength: 5,
                                maxLength: 80
                            })}
                            placeholder="Company Name"
                            />
                            {errors.name && 
                                <Text color="red" size="xs">{errors.name.message}</Text>
                            }
                    </FormControl>
                    <FormControl id="about" isRequired>
                        <FormLabel>Description:</FormLabel>
                        <Input 
                            {...register("about", {
                                required: "Please enter a description for the company of at least 10 characters",
                                minLength: 10,
                                maxLength: 512
                            })}
                            placeholder="Company blurb"/>
                            {errors.about && 
                                <Text color="red" size="xs">{errors.about.message}</Text>
                            }
                    </FormControl>
                    <FormControl id="contact_name" isRequired>
                        <FormLabel>Admin name</FormLabel>
                        <Input 
                            {...register("contact_name", {
                                required: "Please enter a contact name",
                            })}/>
                            {errors.contact_name && 
                                <Text color="red" size="xs">{errors.contact.message}</Text>
                            }
                    </FormControl>
                    <FormControl id="Admin email" isRequired>
                        <FormLabel>Admin email</FormLabel>
                        <Input 
                        placeholder="example@email.com"
                        {...register("contact_email", {
                            required: "Please enter a valid admin email address",
                            email: true,
                            minLength: 5,
                            maxLength: 80
                            })}
                        />
                        {errors.keywords && 
                            <Text color="red" size="xs">{errors.contact_email.message}</Text>
                        }
                    </FormControl>
                    <Box width="100%" align="right">
                        <Button type="submit" colorScheme="blue" variant="outline" mr={2}>Create</Button>
                        {/* Pass additional buttons */}
                        {children}
                    </Box>
                </VStack>
            </form>
        </Box>
    )
}

export default AdminCreateOrgansiationForm