
import Keyword from '../components/Keyword'
import Keywords from '../components/Keywords'
import { Box } from '@chakra-ui/react'
import { updateDoc, doc } from 'firebase/firestore'

const KeywordContainer = ({file, collectionRef, canEdit = false}) => {

    const handleEdit = (payload) => {
        const keywords = payload.split(",")
        updateDoc(doc(collectionRef, file.id), {keywords})
    }

    return (
        <Box>
            { canEdit 
                ? <Keyword content={file.keywords} handleSave={handleEdit} />
                : <Keywords keywords={file.keywords} />
            }
        </Box>
    )
}

export default KeywordContainer