import { VStack, Text } from "@chakra-ui/react"

import { useFirestore, useFirestoreDocData } from "reactfire"
import { doc } from '@firebase/firestore' 

import Spinner from "../components/Spinner"
import UserProjectsContainer from "./UserProjectsContainer"

const UserInformationContainer = ({
    user = []
}) => {

    // List projects user has access to.
    // Remove access to all projects/single project
    // suspend user account
    
    const firestore = useFirestore()
    const userDocument = doc(firestore, `users/${user.id}`)
    const {status, data: userData} = useFirestoreDocData(userDocument, {
        idField: 'id'
    })
    
    if (!status === 'success' || !userData) {
        return <Spinner />
    }

    return (
        <VStack p={3} align="left">
            <Text>{user.name}</Text>
            <Text>Project Access:</Text>
            {userData.projects?.length > 0 &&
                <UserProjectsContainer user={userData}/>
            }
        </VStack>
    )
}

export default UserInformationContainer