import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
  } from '@chakra-ui/react'

const ModalContainer = ({
    children,
    size='xl', // xs to full
    title = 'Modal Title',
    isOpen,
    onClose

}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={size}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
            {children}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default ModalContainer