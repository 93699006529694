
import React from 'react'
import ReactMapGL from 'react-map-gl'
import 'mapbox-gl/dist/mapbox-gl.css';
import { Spinner, Box, Text, Flex } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import {loading} from '../redux/mapSelector'

const defaultMapboxApiAccessToken =
    'pk.eyJ1IjoiYy1jb3JlIiwiYSI6ImNqZWl3bzI0MzBsNm4zM21lcjBsZXpvajUifQ.gmSOoJC8y-fk9rNz88-gTg'
const defaultMapStyle = 'mapbox://styles/mapbox/light-v10'

function Map ({
    mapboxApiAccessToken = defaultMapboxApiAccessToken,
    width = '100%',
    height = '100%',
    mapStyle = defaultMapStyle,
    onViewportChange,
    viewport = {},
    mapOptions = {
        hash: false,
    },
    onLoad,
    children,
}) {    
    
    const isLoading = useSelector(loading)

    return (
        <ReactMapGL
            {...viewport}
            width={width}
            height={height}
            mapboxApiAccessToken={mapboxApiAccessToken}
            mapStyle={mapStyle}
            onViewportChange={onViewportChange}
            onLoad={onLoad}
        >
            {/* // TODO - Make this a component */}
            {isLoading && 
                <Box
                    position="absolute" 
                    top="5px" 
                    left="5px"
                    bg="white"
                >
                <Flex direction="row" p={2}>
                    <Spinner color='blue.500' />
                    <Text ml="3">Loading...</Text>
                </Flex>
            </Box>}
            {children}
        </ReactMapGL>
    )
}

export default Map
