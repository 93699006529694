import { useFirestore, useFirestoreDocData, useUser, useSigninCheck } from "reactfire"
import { doc } from 'firebase/firestore'

import Spinner from '../components/Spinner'

import { 
    Box, Flex
} from "@chakra-ui/react"

import ErrorMessage from '../components/ErrorMessage'
// import ProjectMapContainer from "./ProjectMapContainer"
import { Outlet } from "react-router"

const SharedHomeContainer = () => {
    const firestore = useFirestore()
    const { status: usrStatus, data: user } = useUser()
    const userRef = doc(firestore, `users/${user.uid}`)
    const { status: docStatus, data: userDoc} = useFirestoreDocData(userRef)
    const { status: claimStatus, data: isAdmin } = useSigninCheck({ requiredClaims: { admin: true } });

    if ( docStatus !== 'success' || usrStatus !== 'success') return <Spinner />

    if (!userDoc) {
        return <ErrorMessage message='User not found' />
    }

    const userProjects = userDoc.projects
    
    return (
        // <Box
        //     // bg="white"
        //     >
            <Flex
                backgroundColor="gray.100"
                height='calc(100vh - 75px)'
                maxH='calc(100vh - 75px)'
            >
                <Box 
                    w={{base: '100%'}} 
                    // mr={{base: 0, md: 0}}
                    boxShadow="sm" rounded="md" bg="white"
                    overflow="scroll"
                    p={4}
                    m={2}
                >
                    {/* Only render projects view if User has projects or is an admin */}
                    {claimStatus === 'success' && (userProjects.length || isAdmin.hasRequiredClaims ) 
                        ? <Outlet context={[userProjects, isAdmin]} />
                        : <ErrorMessage status='warning' message="You do not have any shared projects" />}
                </Box>
            </Flex>
        // </Box> 
    )
}

export default SharedHomeContainer