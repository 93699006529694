import { Flex } from '@chakra-ui/react'

/**
 * Header for containing titles, menus, and icons
 */
const Header = ({
    children,
    height = '75px',
    padding = '6',
    menuItems = [],
    headerStyle = 'transparent',
}) => {
    let bgStyle
    switch (headerStyle) {
        case 'transparent':
            bgStyle = { backgoundColor: 'none' }
            break

        case 'light':
            bgStyle = { backgroundColor: 'white' }
            break

        case 'dark':
            bgStyle = { backgroundColor: 'dark-blue' }
            break

        default:
            bgStyle = {
                backdropFilter:
                    'blur(8px) saturate(100%) contrast(45%) brightness(150%)',
                backgroundColor: 'rgba(120, 155, 255, 0.3)',
            }
            break
    }

    return (
        <>
            <Flex
                width={{ base: '100%', md: '100%' }}
                style={bgStyle}
                borderRadius='0px'
                zIndex={3}
                align="center"
            >
                {children}
            </Flex>
        </>
    )
}

export default Header
