// React
import { cloneElement } from 'react'

// Firebase/reactfire
import { collection } from 'firebase/firestore'
import { useFirestore, useFirestoreCollectionData } from 'reactfire'

// C-CORE
import Spinner from '../components/Spinner'

const CollectionContainer = (props) => {
    const firestore = useFirestore();
    const ref = collection(firestore, props.collectionPath)
    const { data } = useFirestoreCollectionData(ref, {
        idField: 'id'
    })
  
    // TODO: Deal with missing or insufficient permissions error + connection errors
    if (!data) {
        return <Spinner />
    }

    return <>{cloneElement(props.children, {...props, collection: data} )}</>
}

export default CollectionContainer