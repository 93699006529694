import React from 'react'
// CHakra
import { Flex, Box } from '@chakra-ui/react'
import { collection } from 'firebase/firestore'
import { useContext } from 'react'
import { useFirestore, useFirestoreCollectionData } from 'reactfire'

import ProjectCard from '../components/ProjectCard'
import Spinner from '../components/Spinner'
import { TenantContext } from './TenantContainer'
import ProjectCreateContainer from './ProjectCreateContainer'

const ProjectListContainer = () => {    

    const orgId = useContext(TenantContext)
    const firestore = useFirestore()
    const projectCollection = collection(firestore, `organisations/${orgId}/projects`)
    const { status, data: projects } = useFirestoreCollectionData(projectCollection, {idField: 'id'})

    if (!status === 'success') return <Spinner />
    return (

        <Flex gap={1} 
            width={{base: "calc(100vw)", md: "calc(100vw - 65px)" }}
            height="calc(100vh - 125px)"
            overflow="hidden"
            >
            <Box
                layerStyle="card.base"
                overflow='scroll'
                p={1}
                m={2}
                flexGrow={1}
            >
                <Flex
                    justifyContent="right"
                    p={1}
                >
                    <ProjectCreateContainer collection={projectCollection}/>
                </Flex>
                <Flex 
                    flexWrap="wrap"
                    gap={1}
                >
                    {
                    projects &&
                        Object.values(projects).map((project, i) => {
                            return (
                                <ProjectCard 
                                    project={project} key={i} 
                                    link={`${project.id}`}
                                />
                            )
                        }
                    )}
                </Flex>
            </Box>
        </Flex>
    )
}

export default ProjectListContainer
