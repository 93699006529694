import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import MapContainer from './MapContainer'
import RasterTileContainer from './RasterTileContainer'
import ProjectLayersContainer from './ProjectLayersContainer'
import mapDuck from '../redux/mapDuck'
import bbox from '@turf/bbox'
import { featureCollection } from '@turf/helpers'
import { getSelectedFile } from '../redux/imageLayerSelector'

const FileListMapContainer = ({files = []}) => {
    const dispatch = useDispatch()
    const selectedFile = useSelector(getSelectedFile)

    const jsonItems = files.filter(item => item.status === 'success').map(item => {
        return {
            ...item,
            geometry: JSON.parse(item.geometry)
        }
    })

    useEffect(() => {
        const fc = featureCollection(jsonItems)
        const filesBbox = bbox(fc)
        if (jsonItems.length) dispatch(mapDuck.actions.fitBounds(filesBbox))
    }, [jsonItems, dispatch])
    
    return (
        <MapContainer>
            {files && 
                <>
                    <ProjectLayersContainer jsonFeatures={jsonItems} />
                    <RasterTileContainer file={selectedFile} />
                </>}
        </MapContainer>
    )
}

export default FileListMapContainer