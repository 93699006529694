import { Text } from "@chakra-ui/layout"
import AdminOrganisationItem from '../components/AdminOrganisationItem'

const ListOrganisationsContainer = ({collection = []}) => {
    return (
        collection.length > 0 
            ? collection.map((item, index) => <AdminOrganisationItem organisation={item} key={index} />)
            : <Text>There are no organisations in this deployment</Text>
    )
}

export default ListOrganisationsContainer