import { useSigninCheck } from 'reactfire'

import Spinner from '../components/Spinner'
import ErrorUnauthorised from '../components/ErrorUnauthorised'
import AdminHome from './AdminHome'
import { Navigate } from 'react-router'

const Home= () => {

    // TODO this dluplicated the new auth check. 

    const { status, data: claims } = useSigninCheck({
        validateCustomClaims: (userClaims) => {
            const claims = {
                org: false,
                isAdmin: false,
            }
            // TODO: Check against list of valid orgs? might be slow...??
            claims.org = userClaims.organisation !== 'undefined'
            claims.isAdmin = userClaims.admin === true
            return {hasRequiredClaims: claims}
        }
    })
    
    if (status !== 'success') {
        return <Spinner />
    }

    if (!claims.signedIn) {
        return <Navigate to="/login" replace />
    }
    
    // If no organisation claim:
    if (!claims.hasRequiredClaims.org) {
        return <ErrorUnauthorised />
    }

    // User is authenticated, and has an org claim:
    // Need to add routing in here rather than conditional display.
    return (
        claims.hasRequiredClaims.isAdmin
            ? <AdminHome />
            : <Navigate to="shared-projects" /> //<UserHome />
    )

}

export default Home
