import { useEffect } from "react";

import { useForm } from "react-hook-form";
import { FormControl, FormLabel } from "@chakra-ui/form-control"
import { Input, Button, Box, Text, VStack } from "@chakra-ui/react"

const ProjectCreateForm = ({
    handleCreate,
    children
}) => {
    const { register, handleSubmit, formState: { errors, isSubmitSuccessful }, reset } = useForm();
    
    useEffect(() => {
        if (isSubmitSuccessful) {
          reset();
        }
      }, [isSubmitSuccessful, reset])

    return (
        <Box>
            <form onSubmit={handleSubmit(handleCreate)}>
                <VStack pt={4}>
                    <Text>Add a new user:</Text>
                    <FormControl id="name" isRequired>
                        <FormLabel>Name:</FormLabel>
                        <Input
                            required
                            {...register("name", {
                                required: "Please enter the user's name",
                                minLength: 5,
                                maxLength: 200
                            })}
                            placeholder="User's Full Name"
                            />
                        {errors.name && 
                            <Text color="red" size="xs">{errors.name.message}</Text>
                        }
                    </FormControl>
                    <FormControl id="email" isRequired>
                        <FormLabel>Email Address:</FormLabel>
                        <Input
                            type="email"
                            {...register("email", {
                                required: "Please enter the users email address",
                                email: true,
                                minLength: 5,
                                maxLength: 512
                            })}
                            placeholder="email"/>
                                            {errors.email && 
                            <Text color="red" size="xs">{errors.email.message}</Text>
                        }
                    </FormControl>
                    <FormControl id="company" isRequired>
                        <FormLabel>Company Name</FormLabel>
                        <Input 
                            {...register("company", {
                                required: "Please enter a company name",
                            })}
                        />
                        {errors.company && 
                            <Text color="red" size="xs">{errors.company.message}</Text>
                        }
                    </FormControl>
                    <Box width="100%" align="right">
                        <Button type="submit" colorScheme="blue" variant="outline" mr={2}>Create</Button>
                        {/* Pass additional buttons */}
                        {children}
                    </Box>
                </VStack>
            </form>
        </Box>
    )
}

export default ProjectCreateForm