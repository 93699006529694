import { Wrap, WrapItem, Badge } from '@chakra-ui/react'

const Keywords = ({
    keywords = [],
    colorScheme = 'blue'
}) => {
    return (
        <Wrap spacing="4px">
            {keywords && keywords.map((kw, i) => <WrapItem key={i}><Badge colorScheme={colorScheme}>{kw}</Badge></WrapItem>)}
        </Wrap>
    )
}

export default Keywords