// @flow

function httpToHttps (url: string) {
    const host = url.split('://')[1]
    const httpsUrl = `https://${host}`

    return httpsUrl
}

export default httpToHttps
