// Chakra
import {
    Grid, 
    GridItem, 
} from '@chakra-ui/react' 
import { Navigate } from 'react-router'
import { useSigninCheck } from 'reactfire'
import Spinner from '../components/Spinner'
  

// C-CORE
import HeaderUserContainer from '../containers/HeaderUserContainer'
import MenuLeftContainer from '../containers/MenuLeftContainer'
import SharedHomeContainer from '../containers/SharedHomeContainer'
import TenantContainer from '../containers/TenantContainer'  
import ErrorUnauthorised from '../components/ErrorUnauthorised'

const UserHome = () => {

    const { status, data } = useSigninCheck({
        validateCustomClaims: (userClaims) => {
            const claims = {
                org: false,
            }
            // TODO: Check against list of valid orgs? might be slow...??
            claims.org = userClaims.organisation !== 'undefined'
            return {hasRequiredClaims: claims}
        }
    })

    if (status !== 'success') return <Spinner />

    if(!data.signedIn) return <Navigate to="/login" />
    if (!data.hasRequiredClaims.org) {
        return <ErrorUnauthorised />
    }

    return (
        <TenantContainer>
            <Grid h="100%" w="100%" p={0} templateColumns={{base: "auto", md: "60px auto"}} overflow="hidden">
                <MenuLeftContainer />
                <GridItem w="100%" h="100%">
                    <HeaderUserContainer />
                    <SharedHomeContainer />
                </GridItem>
            </Grid>
        </TenantContainer>
    )
}

  export default UserHome