import { VStack, Text, StackDivider, Flex } from "@chakra-ui/react"

const UserListContaienr = ({
    users = [],
    handleSelectUser,
    selectedId = null
}) => {

    const bgColor = (id) => selectedId === id ? 'ccore.selected' : 'none'

    return (

        <VStack
            divider={<StackDivider borderColor='gray.200' />}
            spacing={0}
            align='stretch'
        >
            {Object.values(users).map((user, i) => (
                <Flex 
                    onClick={() => handleSelectUser(user)}
                    _hover={{
                        backgroundColor: 'gray.100',
                        color: 'black',
                    }}
                    p={3}
                    align="middle"
                    bg={() => bgColor(user.id)}
                    key={i}
                    justifyContent="space-between"
                >
                    <Text>{user.name}</Text>
                    <Text>{user.email}</Text> 

                </Flex>
            ))}
        </VStack>
    )
}

export default UserListContaienr