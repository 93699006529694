import { useState, useRef } from 'react'

// CHakra
import { DeleteIcon } from '@chakra-ui/icons'

import { 
    IconButton,
    AlertDialog,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogBody,
    AlertDialogFooter,
    Button,
    Tooltip
} from '@chakra-ui/react'

const ConfirmationDialogue = ({
    tooltipLabel = '',
    iconComponent = <DeleteIcon />,
    btnVariant = 'outline',
    btnIsRound = false,
    color = 'blue.500',
    btnSize = 'md',
    titleText = 'Confirm Action',
    bodyText = 'Are you sure you want to do this? This action cannot be undone.',
    confirmText = 'Confirm',
    cancelText = 'Cancel',
    handleClose,
    mr = 0,
    ml = 0,
    disabled
}) => {
    const [isOpen, setIsOpen] = useState(false)
    const onClose = () => {
        setIsOpen(false)
    }

    const closeConfirm = () => {
      setIsOpen(false)
      handleClose()
    }
    const cancelRef = useRef()
  
    return (
      <>
        <Tooltip label={tooltipLabel}>
          <IconButton
            disabled={disabled}
            icon={iconComponent} 
            // color={btnColor}
            color={color} 
            size={btnSize}
            onClick={() => setIsOpen(true)} 
            variant={btnVariant} 
            isRound={btnIsRound} 
            ml={ml} 
            mr={mr}/>
        </Tooltip>
        <AlertDialog
          isOpen={isOpen}
          leastDestructiveRef={cancelRef}
          onClose={onClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                {titleText}
              </AlertDialogHeader>
              <AlertDialogBody>
                {bodyText}
              </AlertDialogBody>
              <AlertDialogFooter>
                <Button
                  variant="solid"
                  ref={cancelRef} 
                  onClick={onClose} mr={3}
                  >
                  {cancelText}
                </Button>
                <Button 
                  colorScheme="red" 
                  onClick={closeConfirm} 
                  variant="solid"
                  >
                  {confirmText}
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </>
    )
  }

  export default ConfirmationDialogue