
// import { useEffect } from 'react'
import PolygonLayer from "../components/PolygonLayer"

const ProjectLayersContainer = ({
    jsonFeatures,
    boundsFeature,
}) => {

    return (
        <>
            {boundsFeature 
                && <PolygonLayer item={boundsFeature} id="project_boundary" />}
            
            {jsonFeatures &&
                jsonFeatures.map((item, index) => {
                    return <PolygonLayer item={item} id={item._id} key={index} />
            })}
        </>
    )
}

export default ProjectLayersContainer