
// Firebase
export const apiKey = process.env.REACT_APP_API_KEY
export const authDomain = process.env.REACT_APP_AUTH_DOMAIN
export const databaseURL = process.env.REACT_APP_DATABASE_URL
export const projectId = process.env.REACT_APP_PROJECT_ID
export const storageBucket = process.env.REACT_APP_STORAGE_BUCKET
export const messagingSenderId = process.env.REACT_APP_MESSAGING_SENDER_ID
export const appId = process.env.REACT_APP_APP_ID
export const firebaseConfig = {
  apiKey, authDomain, databaseURL, projectId, storageBucket, messagingSenderId, appId
}

// Mapbox
export const baseUri = process.env.REACT_APP_API

// ES
export const es_key = process.env.REACT_APP_ES_KEY
export const es_index= process.env.REACT_APP_ES_INDEX
export const es_type = process.env.REACT_APP_ES_TYPE
export const es_url = process.env.REACT_APP_ES_URL

// Mapbox
export const mapboxAccessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN
export const basemapStyle = 'mapbox://styles/mapbox/light-v8'
export const defaultViewport = {
    latitude: 55.6434,
    longitude: -4.17,
    zoom: 12,
    width: 250,
    height: 250
}