// React
import defaultStore from './redux/store'
import { Provider } from 'react-redux'

// Firebase
import { getAuth, connectAuthEmulator } from 'firebase/auth'
import { AuthProvider, FunctionsProvider, useFirebaseApp, FirestoreProvider, StorageProvider } from 'reactfire';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';
import { connectFunctionsEmulator, getFunctions } from '@firebase/functions';
import { getStorage } from 'firebase/storage';

// TODO Local development/testing imports for emulation based on env variable.

// Chakra
import {
  ChakraProvider,
  extendTheme 
} from '@chakra-ui/react'
import theme from './theme'

import AppRoutes from './routes/AppRoutes';

const customTheme = extendTheme(theme)

function App() {

  const app = useFirebaseApp()
  const functions = getFunctions(app)

  // Initialise auth:
  const auth = getAuth(app)
  const db = getFirestore(app)
  const storage = getStorage(app)

  if (process.env.REACT_APP_EMULATE === 'true') {
    // Set up emulators
    connectFirestoreEmulator(db, 'localhost', 8080);
    connectAuthEmulator(auth, 'http://localhost:9099');
    connectFunctionsEmulator(functions, "localhost", 5001);
  }
  
  return (
    <ChakraProvider resetCSS theme={customTheme}>
      <Provider store={defaultStore}>
        <AuthProvider sdk={auth}>
          <FirestoreProvider sdk={db}>
            <FunctionsProvider sdk={functions}>
              <StorageProvider sdk={storage}>
                <AppRoutes />
              </StorageProvider>
            </FunctionsProvider>
          </FirestoreProvider>
        </AuthProvider>
      </Provider>
    </ChakraProvider>
  );
}

export default App;
