import React, { useEffect, useContext } from 'react'
import { Source, Layer, MapContext } from 'react-map-gl'

function useSource ({ map, url, id }) {
    useEffect(() => {
        const source = map.getSource(id)
        if (source) {
            // Update source url and repaint
            // https://github.com/mapbox/mapbox-gl-js/issues/2941#issuecomment-518631078
            source.tiles = [url]
            map.style.sourceCaches[id].clearTiles()
            map.style.sourceCaches[id].update(map.transform)
            map.triggerRepaint()

            // window.map = map
            // window.source = source
        }
    }, [ map, id, url])
}

function RasterLayer ({
    id,
    tiles,
    item = undefined,
    bounds = undefined,
    type = 'raster',
    scheme = 'tms',
    minzoom = 0,
    maxzoom = 22,
    beforeId,
}) {
    const { map } = useContext(MapContext)
    useSource({ map, id, url: tiles[0] })

    const layer = { type: 'raster' }

    return (
        <>
            <Source
                id={id}
                type={type}
                tiles={tiles}
                scheme={scheme}
            >
                <Layer id={id} beforeId={beforeId} {...layer} />
            </Source>
        </>
    )
}

export default RasterLayer
