// React
import Header from '../components/Header'
import Logo from '../components/Logo'
import IdentityContainer from '../containers/IdentityContainer'
import HeaderMobile from '../components/HeaderMobile'
import MenuMobile from '../components/MenuMobile'
import { Box, Input } from '@chakra-ui/react'

// Firestore/reactfire
import { useSigninCheck } from 'reactfire'

// Chakra
import { useDisclosure, Spacer } from '@chakra-ui/react'

// C-CORE
import UploadContainer from '../containers/UploadContainer'
// import SearchContainer from './SearchContainer'

const HeaderContainer = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const menuItems = []
    const { status, data: adminCheck } = useSigninCheck({requiredClaims: {admin: true}})
    
    return (
        <Header menuItems={menuItems}>
            <Logo />
            <Input placeholder='Search coming soon' disabled width="300px" />
            {/* <SearchContainer /> */}
            <Spacer />
            <Box display={{base: "none", md:"block"}}>
                {status === 'success' && adminCheck.hasRequiredClaims &&
                    <UploadContainer />
                }
            </Box>

            <IdentityContainer />
            <HeaderMobile onOpen={onOpen} title='s34d.io' />
            <MenuMobile
                onClose={onClose}
                isOpen={isOpen}
                menuItems={menuItems}
            />
        </Header>
    )
}

export default HeaderContainer
