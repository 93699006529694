import { Center, Image } from '@chakra-ui/react'
import logo from '../images/logo_stacked.png'

const Logo = ({ height = '75px' }) => {
    return (
        <Center
            minW={{ base: '200px', sm: '0px' }}
            h={height}
            maxH="100px"
            padding='0px'
            display={{ base: 'none', md: 'inherit' }}
            >
            <Image src={logo} alt='C-CORE Logo' h="60px"/>
        </Center>
    )
}
export default Logo
