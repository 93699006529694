import EditableItem from '../components/EditableItem'
import { updateDoc, doc } from 'firebase/firestore'
// import { useFirestore, useUser } from 'reactfire'

const EditItemContainer = ({file, name, collectionRef, canEdit = false}) => {

    const handleEdit = (payload) => {
        const data = {}
        data[name] = payload
        updateDoc(doc(collectionRef, file.id), data)
    }
    
    return canEdit 
        ? <EditableItem content={file[name]} handleSave={handleEdit} />
        : file[name]
}

export default EditItemContainer