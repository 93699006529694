// React
import { useContext } from 'react'

// Reactfire / Firebase
import { useFirestore } from 'reactfire'
import { doc, deleteDoc} from 'firebase/firestore'

// Chakra
import { CloseIcon } from '@chakra-ui/icons'

// C-CORE
import { TenantContext } from './TenantContainer';
import ConfirmationDialogue from "../components/ConfirmationDialogue";
import { useToast } from '@chakra-ui/react'

const ProjectRemoveFileButtonContainer = ({
    project,
    file,
    variant = 'solid',
    size="sm",
    color='blue.400'
}) => {

    const toast = useToast()
    const orgId = useContext(TenantContext)
    const firestore = useFirestore()
    const projectRef = doc(firestore, `/organisations/${orgId}/projects/${project.id}`)

    const handleRemoveFile = async () => {
        try {
            await deleteDoc(doc(projectRef, 'project_files', file.id))

            // TODO: refactor toasts into somthing neater.
            toast({
                title: "File Removed",
                description: "File removed from the project",
                status: "success",
                duration: 9000,
                isClosable: true,
            })
        } catch (error) {
            toast({
                title: "Opps!",
                description: "File could not be deleted",
                status: "warning",
                duration: 9000,
                isClosable: true,
            })
        }
    }

    return (
        <ConfirmationDialogue
            tooltipLabel="Remove file from project"
            mr={0}
            ml={0}
            handleClose={handleRemoveFile}
            iconComponent={<CloseIcon />}
            btnVariant={variant}
            btnIsRound={false}
            color={color}
            btnSize={size}
            titleText='Remove File Confirmation'
            bodyText = 'Are you sure you want to remove this File? Users of this project will no longer be able to access it'
            confirmText = 'Remove'
            cancelText = 'Cancel'
            disabled={false}
        />
    )
}

export default ProjectRemoveFileButtonContainer