import { createContext, useState, useEffect } from "react";
import { useAuth } from 'reactfire';
import Spinner from '../components/Spinner'

export const TenantContext = createContext()

const TenantContainer = ({children}) => {
    const [token, setToken] = useState(null)
    const auth = useAuth()
    
    useEffect(() => {
        async function getToken (){
            const user = auth.currentUser
            const token = await user.getIdTokenResult()
            setToken(token)
        }
        getToken()
    }, [auth])
        
    if (!token) {
        return <Spinner />
    }

    if (!token.claims.organisation) {
        return <>You do not belong to an organisation, please contact your administrator</>
    }

    return (
        <TenantContext.Provider value={token.claims.organisation}>
            {children}
        </TenantContext.Provider>
    )
}

export default TenantContainer