import React from "react";
import { Routes, BrowserRouter as Router, Route } from "react-router-dom";
import Home from "./Home";
import UserHome from "./UserHome";
import Login from "./Login";
import Error from "./Error";
import SharedProjectsContainer from "../containers/SharedProjectsContainer";
import SharedProjectFilesContainer from "../containers/SharedProjectFilesContainer";

import FileListContainer from "../containers/FileListContainer"

import ProjectListContainer from "../containers/ProjectListContainer";
import ProjectContainer from "../containers/ProjectContainer";
import UserManagerContainer from "../containers/UserManagerContainer"
import AdminManagerContainer from "../containers/AdminManagerContainer"

import AuthCheck from "../components/AuthCheck";
import ProjectMapContainer from "../containers/ProjectMapContainer";
import ProjectUsersContainer from "../containers/ProjectUsersContainer";

const AppRoutes = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home />} exact >
                    <Route
                        path="projects/"
                        element={
                            <AuthCheck admin={true}>
                                <ProjectListContainer />
                            </AuthCheck>
                        }
                    />
                    <Route
                        path="/projects/:projectId"
                        element={
                            <AuthCheck admin={true}>
                                <ProjectContainer />
                            </AuthCheck>
                        }
                    >
                        <Route
                            path=""
                            element={<ProjectMapContainer />}
                        />
                        <Route
                            path="users"
                            element={<ProjectUsersContainer />}
                        />
                    </Route>
                    <Route
                        path="/files"
                        element={
                            <AuthCheck admin={true}>
                                <FileListContainer />
                            </AuthCheck>
                        }
                    />
                    <Route
                        path="/users"
                        element={
                            <AuthCheck admin={true}>
                                <UserManagerContainer />
                            </AuthCheck>
                        } 
                    />
                    <Route
                        path="/admin"
                        element={
                            <AuthCheck admin={true} superAdmin={true}>
                                <AdminManagerContainer />
                            </AuthCheck>
                        }
                    
                    />
                </Route> 
                <Route 
                    path="/shared-projects" 
                    element={<UserHome />} 
                    exact
                >
                    <Route 
                        path=""
                        element={<SharedProjectsContainer />} 
                    />
                    <Route
                        path="/shared-projects/:projectId"
                        element={<SharedProjectFilesContainer />}
                    />
                </Route>
                <Route 
                  path="/login" 
                  element={<Login />} 
                  exact 
                />
                <Route 
                    path="*" 
                    element={<Error />} 
                />
            </Routes>
        </Router>
    );
};

export default AppRoutes;
