import { Text, Grid, GridItem } from "@chakra-ui/react"

const ProjectUserItem = ({
    user,
    children,
    signIn = true
}) => {
    return (
        <Grid templateColumns="repeat(7, 1fr)" gap={1}>
            <GridItem colSpan={{base: 5, lg: 2}}>
                {user.name}
            </GridItem>
            <GridItem colSpan={2} display={{base: 'none', lg: 'block'}}>
                {user.company}
            </GridItem>
            {signIn &&
                <GridItem colSpan={2} display={{base: 'none', lg: 'block'}}>
                    <Text color="gray.500" as="i">{user.email}</Text>
                </GridItem>}
            <GridItem colStart={7} colEnd={8} align="right">
                {children}
            </GridItem>
        </Grid>
    )
}

export default ProjectUserItem