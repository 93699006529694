import { useContext } from "react"
import { ReactiveBase } from "@appbaseio/reactivesearch"
import { es_url, es_key, es_type } from '../config'
import { TenantContext } from './TenantContainer'

const ReactiveSearchContainer = ({children}) => {
    const tenantId = useContext(TenantContext)
    const indexId = tenantId.toLowerCase()
    return (
        <ReactiveBase
            url={es_url}
            app={indexId}
            credentials={es_key}
            type={es_type}
            // theme={theme}
            enableAppbase={true}
            >
            {children}
        </ReactiveBase>
    )
}

export default ReactiveSearchContainer