import { useDispatch, useSelector } from 'react-redux'
import { useContext, useEffect, useMemo } from "react"
import { collection } from "firebase/firestore"
import { useFirestoreCollectionData, useFirestore } from "reactfire"

import bbox from '@turf/bbox'
import bboxPolygon from '@turf/bbox-polygon'
import { featureCollection } from '@turf/helpers'

// CCORE
import MapContainer from './MapContainer';
import ProjectLayersContainer from './ProjectLayersContainer';
import RasterTileContainer from './RasterTileContainer';
import { TenantContext } from "./TenantContainer"
import { useParams } from "react-router"
import mapDuck from '../redux/mapDuck'
import { getSelectedFile } from '../redux/imageLayerSelector'

function ProjectMapContainer () {

    // If there is no project or file Selected, show all projects:

    const { projectId } = useParams()
    const orgId = useContext(TenantContext)
    const dispatch = useDispatch()
    const firestore = useFirestore()

    const file = useSelector(getSelectedFile)

    const projectRef = collection(firestore, `organisations/${orgId}/projects/${projectId}/project_files`)
    const { data } = useFirestoreCollectionData(projectRef, {
        idField: 'id'
    })

    // TODO: Why?
    const items = data || []

    const jsonItems = items.filter(item => item.status === 'success').map(item => {
        return {
        ...item,
        geometry: JSON.parse(item.geometry)
        }
    })

    const boundsObject = useMemo(() => {
        const input = featureCollection(jsonItems)
        const bounds = bbox(input)
        const boundsFeature = bboxPolygon(bounds)
        return boundsFeature
    }, [jsonItems])

    useEffect(() => {
        // If we have project JSON, fit to bounds
        if (jsonItems.length) {
            dispatch(mapDuck.actions.fitBounds(boundsObject.bbox))
            return
        }
        // No json, fit to default extent

    }, [jsonItems, boundsObject, dispatch])

    return (
        <MapContainer>
            {/* TODO: These should/could be loaded via routes into an outlet here */}

            {file && projectId &&
                <RasterTileContainer file={file} />}

            {items.length > 0 && projectId &&
                <ProjectLayersContainer jsonFeatures={jsonItems} boundsFeature={boundsObject} />}

        </MapContainer>
    )
}

export default ProjectMapContainer