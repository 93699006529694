import { Flex, Box, Text, Center } from '@chakra-ui/react'
import { WarningIcon } from '@chakra-ui/icons'
const ErrorUnauthorised = () => {
    return (
        <Flex 
            width="Full" 
            align="center" 
            height="100vh"
            justifyContent="center" 
            >
                <Box maxWidth="400px">
                    <Center>
                        <WarningIcon color="red.500" w={20} h={20}/>
                        <Text m={8}>Error - Unauthorised</Text>
                    </Center>
                </Box>
            </Flex>
    )
}

export default ErrorUnauthorised