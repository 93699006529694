import { useState, useEffect, useCallback } from "react";
import { useStorage } from "reactfire";
import { ref, getDownloadURL } from "firebase/storage";
import { Image } from "@chakra-ui/react";
import placeholder from "../images/placeholder_600x400.svg"

const Thumbnail = ({ 
    imagePath, 
    boxSize='100px',
    fit="fill",
    height="150px"
}) => {
    const storage = useStorage()
    // const imagePath = file?.assets?.thumbnail?.href
    const imgRef = ref(storage, imagePath)
    
    const [data, setData] = useState()

    const fetchURI = useCallback(async () => {
        const data = await getDownloadURL(imgRef)
        setData(data);
    }, [imgRef])
      
    useEffect(() => {
        fetchURI()
          .catch()
    },[fetchURI])

    return (
        <Image
            htmlHeight={height}
            fit={fit} 
            src={data} 
            boxSize={boxSize}
            objectFit='cover'
            fallbackSrc={placeholder}
        />
    )
}
 
export default Thumbnail;
