import { Grid, GridItem } from '@chakra-ui/react'

const AdminOrganisationItem = ({organisation}) => {
    return (
        <Grid templateColumns="repeat(5, 1fr)" gap={3}>
            <GridItem colSpan={2}>
                {organisation.name}
            </GridItem>
            <GridItem colSpan={2}>
                {organisation.contact_name}
            </GridItem>
            <GridItem colSpan={1}>
                <span>del btn here</span>
            </GridItem>
        </Grid>
    )
}

export default AdminOrganisationItem