/* eslint-disable react/prop-types */
import React from "react"
// Chakra
import {
    ButtonGroup
} from "@chakra-ui/button"

// CCORE
import FileDeleteButton from "../components/FileDeleteButton"
import FileDownloadButton from "../components/FileDownloadButton"
import FilePreviewButton from "../components/FilePreviewButton"
import FileRemoveFromProjectButton from "../containers/FileRemoveFromProjectButton"

const FileActionButtonContainer = ({
    file = { isCog: false, id: null, storage_key: null, name: null},
    size = 'sm',
    variant = 'solid',
    colorScheme = 'blue',
    project = null,
    color = "blue.400",
    del = true,
    removeFromProject = false,
    preview = true,
    download = true
}) => {

    return (
        <ButtonGroup size={size} variant={variant} colorScheme={colorScheme} spacing={1}>
            {/* // TODO Make this a component with error state */}
            {preview &&
                <FilePreviewButton file={file} variant={variant} color={color} />}
            {download &&    
                <FileDownloadButton project={project} file={file}  color={color} variant={variant} />}
            {del &&
                <FileDeleteButton file={file} variant={variant}  color={color} ml={0}/>}
            {removeFromProject &&
                <FileRemoveFromProjectButton file={file} project={project} color={color} variant={variant} />}
        </ButtonGroup>
    )
}

export default FileActionButtonContainer
