import { 
    useContext, 
} from 'react'
import { useFirestore, useFirestoreCollectionData } from 'reactfire'
import { collection, query, where } from '@firebase/firestore'

import { 
    Text,
    Flex,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink
} from '@chakra-ui/react'

import { TenantContext } from "./TenantContainer"
import Spinner from '../components/Spinner'
import ProjectCard from '../components/ProjectCard'
import { useOutletContext } from 'react-router'

const SharedProjectsContainer = () => {
    
    const firestore = useFirestore()
    const [userProjects, isAdmin] = useOutletContext()
    
    const orgId = useContext(TenantContext)
    const projectsRef = collection(firestore, `organisations/${orgId}/projects`)
    const queryStatement = userProjects.length ? userProjects : []

    const projectQuery = isAdmin?.hasRequiredClaims 
        ? projectsRef // Return all projects as user is an admin
        : query(projectsRef, where('__name__', 'in', queryStatement)) // Return projects where in user projects

    const {status, data: projects} = useFirestoreCollectionData(projectQuery, { idField: 'id'})

    if (status === 'loading') {
        return <Spinner />
    }

    return (
        <>
            <Breadcrumb ml="2" mb="2">
                <BreadcrumbItem isCurrentPage>
                    <BreadcrumbLink >
                        My Projects
                    </BreadcrumbLink>
                </BreadcrumbItem>
            </Breadcrumb>
            <Flex wrap="wrap">
                {projects?.length
                    ? projects.map((project, index) => (
                        <ProjectCard 
                            project={project} 
                            key={`sp_${index}`} 
                            link={`/shared-projects/${project.id}`}
                        />
                      ))
                    : <Text>You do not have access to any projects.</Text>
                }
            </Flex>
        </>
    )
}

export default SharedProjectsContainer