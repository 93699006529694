// React
import { useContext } from 'react'

// CHakra
import { 
    Box, 
    Flex } from '@chakra-ui/react'

// Reactfire / Freibase
import { doc, collection, query } from 'firebase/firestore'
import { useFirestore, useFirestoreCollectionData } from 'reactfire'

// C-CORE
import { TenantContext } from './TenantContainer'
import Spinner from '../components/Spinner'

import FileCard from '../components/FileCard'
import FilesEmpty from '../components/FilesEmpty'
import FileListMapContainer from './FileListMapContainer'
import FileActionButtonContainer from './FileActionButtonContainer'
import ErrorMessage from '../components/ErrorMessage'

const FileListContainer = () => {
    const orgId = useContext(TenantContext)
    const firestore = useFirestore();

    const ref = doc(firestore, 'organisations', orgId);
    const filesCollection = collection(ref, 'files');

    const fileQuery = query(filesCollection);
    const { data: files, status, error } = useFirestoreCollectionData(fileQuery, {idField: 'id'});

    // TODO: Deal with missing or insufficient permissions error.
    if (error) return <ErrorMessage message="Error: Could not load files" />
    if (!status === 'success' || !files) return <Spinner />

    return (
        <Flex gap={1} 
            width="calc(100vw - 65px)"
            height="calc(100vh - 125px)"
            overflow="hidden"
        >
            <Box
                bg="white" 
                borderRadius="md"
                overflow='scroll'
                p={1}
                flexGrow={1}
            >
                {files && Object.keys(files).length > 0
                    ?   Object.values(files).map((file, index) => {
                            return (
                                <FileCard 
                                    key={file.id} 
                                    file={file}
                                    collectionRef={filesCollection}
                                    canEdit={true}
                                    >
                                    <FileActionButtonContainer 
                                        file={file} 
                                        variant="outline" 
                                        size="sm" 
                                        canEdit={true} 
                                    />
                                </FileCard>
                            )
                        })
                    :   <FilesEmpty />
                }
            </Box>
            <Box                
                bg="white" 
                borderRadius="md" 
                width={['50%']} p={1}
            >
                <FileListMapContainer files={files} />
            </Box>
        </Flex>
    )
}

export default FileListContainer
