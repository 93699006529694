import { Navigate } from 'react-router';
import { useSigninCheck } from 'reactfire'
import Spinner from '../components/Spinner';
import SignInContainer from '../containers/SignInContainer'

const Auth = () => {
	const { status, data: signInCheckResult } = useSigninCheck();

	if (status !== 'success') return <Spinner/>

	return (
		signInCheckResult.signedIn
			? <Navigate to="/" replace />
			: <SignInContainer />
	)
}

export default Auth