
import { useAuth, useUser } from 'reactfire'
import Identity from '../components/Identity'
// import { signOut } from '@firebase/auth'

const IdentityContainer = ({ height = '65px', p = '4', name = null, src = null}) => {
    const { status, data: user } = useUser();
    const auth = useAuth()

    if (status === 'loading') {
      return '';
    }
    
    // Workaround for reactfire issue
    // https://github.com/FirebaseExtended/reactfire/discussions/228
    const clearFirestoreCache = () => {
      const map = globalThis['_reactFirePreloadedObservables'];
      Array.from(map.keys()).forEach(
          (key) => key.includes('firestore') && map.delete(key),
        );
      };

    const handleLogout = () => {

        auth.signOut().then(() => {
          clearFirestoreCache()
        })
    }

    return <Identity handleLogout={handleLogout} user={user} />
}

export default IdentityContainer
