import React, { useState, useEffect } from 'react'
import RasterLayer from '../components/RasterLayer'
import httpToHttps from '../utils/httpToHttps'
import { useDispatch } from 'react-redux'
import mapDuck from '../redux/mapDuck'
import { getTileJson } from '../api'

function RasterTileContainer ({file}) {
    const dispatch = useDispatch()
    const [tileJson, setTileJson] = useState({})

    useEffect(() => {
        if (file) {
            async function fetchTileJson () {
                try {
                    const tileJsonResponse = await getTileJson(file)
                    const tileJson = await tileJsonResponse.json()
                    const { center, minzoom } = tileJson
                    const [longitude, latitude] = center
                    const viewport = { latitude, longitude, zoom: minzoom }
    
                    setTileJson(tileJson)
                    dispatch(mapDuck.actions.changeViewport(viewport))
                    dispatch(mapDuck.actions.setIsLoading(false))
                } catch (error) {
                    // console.log(error)
                }
            }
            fetchTileJson()
        }
    }, [setTileJson, file, dispatch])
    
    const { tiles } = tileJson
    const httpsTiles = tiles ? tiles.map(tile => httpToHttps(tile)) : []

    return (
        <>
            {tiles && <RasterLayer
                id='raster_preview'
                {...tileJson}
                tiles={httpsTiles}
            />}
        </>
    )
}

export default RasterTileContainer
