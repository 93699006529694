import React, { useState } from 'react'
import SignIn from '../components/SignIn'
import { signInWithEmailAndPassword } from '@firebase/auth'
import { useAuth } from 'reactfire'
import 'firebase/auth'

function SignInContainer () {
  
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [fetching, setFetching] = useState(false)
  const [error, setError] = useState(undefined)
  const auth = useAuth()

  const handleLogin = async (event) => {
    if (email === '') {
      setError('Enter your email')
      return
    }

    if (password === '') {
      setError('Enter your password')
      return
    } 

    setError(null)
    setFetching(true)

    try {
      await signInWithEmailAndPassword(auth, email, password)
    } catch (err) {
      setFetching(false)
      setError('Please check your username and password')
    }
  } 

  return (
    <SignIn
      handleLogin={handleLogin}
      email={email}
      password={password}
      error={error}
      isLoading={fetching}
      setEmail={setEmail}
      setPassword={setPassword}
    />
  )
}

export default SignInContainer