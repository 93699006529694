import { VStack, Text } from "@chakra-ui/react"

const AdminOrganisationInformationContainer = ({organisation}) => {
    // Get organisation stats doc...

    return (
        <VStack>
            <Text>Org description</Text>
            <Text>resource usage dashboard - storage dial</Text>
            <Text>No. of projects</Text>
            <Text>No. of files</Text>
            <Text>No. of users</Text>
            <Text>Most accessed assets</Text>
            <Text>Activity graphs</Text>
            <Text>etc...</Text>
        </VStack>
    )
}

export default AdminOrganisationInformationContainer