import React, { useState, useEffect } from 'react'

// Chakra
import {
    Box,
    Tab,
    Tabs,
    TabList,
} from '@chakra-ui/react'

import { useSigninCheck } from 'reactfire'
import { Outlet, useLocation, useNavigate } from 'react-router'
import Spinner from '../components/Spinner'

const Home = () => {
    const { status, data: signInCheck } = useSigninCheck({requiredClaims: {superAdmin: true}})
    const navigate = useNavigate()

    const [selectedTab, setSelectedTab] = useState(0)
    const { pathname } = useLocation()
    
    //  TODO: Use object routing in AppRoutes and share this as a constant.

        
    const handleTabChange = (index) => {
        setSelectedTab(index)
        switch (index) {
            case 0:
                navigate(`/projects`)
                break
            case 1:
                navigate('/files')
                break
            case 2:
                navigate('/users')
                break
            case 3:
                navigate('/admin')
                break
                default:
                    break;
        }
    }
    

    
    useEffect(() => {
        const routes = {
            'projects': { tab: 0, path: '/projects', name: 'projects'},
            'files': { tab: 1, path: '/files', name: 'files'},
            'users': { tab: 2, path: '/users', name: 'users'},
            'admin': { tab: 3, path: 'admin', name: 'admin'}
        }

        const basePath = pathname.split('/')[1]
        if (!basePath) {
            navigate('/projects')
        }

        setSelectedTab(routes[basePath]?.tab)
    },[pathname, navigate])
    
    if (status === 'loading') {
        return <Spinner />
    }
    return (
        <Box height='100%' overflow='hidden'>
            {/* Here down = scrollable box */}
            <Box
                height='calc(100vh - 75px)'
                maxH='calc(100vh - 75px)'
                overflow='scroll'
                backgroundColor="gray.100"
            >
                <Tabs variant="line" onChange={handleTabChange} index={selectedTab}>
                    <TabList 
                        pl={2} 
                        variant=""
                        backgroundColor="white"
                        >
                        <Tab>Projects</Tab>
                        <Tab>All Files</Tab>
                        <Tab>Users</Tab>
                        {/* Limit this tab to superAdmins only via token check */}
                        { signInCheck.hasRequiredClaims &&
                            <Tab>Admin</Tab>
                        }
                    </TabList>
                </Tabs>                
                <Outlet />
            </Box>
        </Box>
    )
}

export default Home
