
import { useFunctions } from "reactfire"
import { httpsCallable } from "@firebase/functions"

import AdminCreateOrgansiationForm from "../components/AdminCreateOrganisationForm"

const AdminCreateOrganisationContainer = ({children}) => {
    const functions = useFunctions()
    const addUser = httpsCallable(functions, 'admin-addNewOrganisation');

    const createOrganisation = async (formData) => {
        try {
            await addUser(formData)
        } catch (err) {
            // TODO: LOG Errors in a useful way!
            console.log(err)
        }
    }

return (
    <AdminCreateOrgansiationForm handleCreate={createOrganisation}>
        {children}
    </AdminCreateOrgansiationForm>
)

}

export default AdminCreateOrganisationContainer