import { useDispatch } from "react-redux"
import { IconButton, Tooltip } from '@chakra-ui/react'
import { MdMap } from 'react-icons/md'
import imageLayerDuck from '../redux/imageLayerDuck'
import mapDuck from '../redux/mapDuck'

const FilePreviewButton = ({
    file,
    variant,
    size="sm",
    color="blue.400"
}) => {
    
    const dispatch = useDispatch()
    const handleFilePreview = (file) => {
        dispatch(mapDuck.actions.setIsLoading(true))
        dispatch(imageLayerDuck.actions.setPreviewFile(file))
    }

    if (file?.status === 'processing') return null
    return (
    <Tooltip label="Preview File">
        <IconButton 
            variant={variant}
            color={color}
            icon={<MdMap />}
            size={size}
            onClick={() => handleFilePreview(file)}
        />
    </Tooltip>
  )
}

export default FilePreviewButton