import { 
    Box,
    Flex
 } from "@chakra-ui/react"

import formatStorageSize from "../utils/formatStorageSize"
import Thumbnail from "./Thumbnail"
import EditItemContainer from "../containers/EditItemContainer"
import KeywordContainer from "../containers/KeywordContainer"
import FileStatus from "./FileStatus"

const FileCard = ({
    file, 
    collectionRef, 
    project, 
    children,
    canEdit = false,
    defaultHeight = '160'
}) => {
    return (
        <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            borderWidth='1px' 
            borderRadius='md' 
            overflow='hidden'
            p={1}
            mb={1}
            flexGrow={1}
            _hover={{
                shadow: 'sm',
              }}
            height={defaultHeight}
        >
            <Box minWidth="150px">
                <Thumbnail imagePath={file.assets?.thumbnail?.href} boxSize='150px' />
            </Box>
            <Box 
                p='4'
                flexGrow={1}
            >
                <Flex 
                    direction='column'
                    alignItems='baseline'
                >
                    <Box
                        minW="100%"
                    >
                        <EditItemContainer 
                            name="file_label" 
                            file={file} 
                            collectionRef={collectionRef} 
                            canEdit={canEdit}
                        />
                    </Box>
                    <Box
                        color='gray.500'
                        fontWeight='semibold'
                        letterSpacing='wide'
                        fontSize='xs'
                        textTransform='uppercase'
                        mt='1'
                    >
                        {formatStorageSize(file.size)}
                    </Box>
  
                    <Box 
                        minW="100%"
                        color='gray.600' 
                        fontSize='sm'
                    >
                        <EditItemContainer 
                            name="description" 
                            file={file} 
                            collectionRef={collectionRef} 
                            canEdit={canEdit}
                        />
                    </Box>
                    {/* <Flex mt='2' align="center" width="100%"> */}
                    <Box minW="100%">
                        <KeywordContainer file={file} collectionRef={collectionRef} canEdit={canEdit}/>
                    </Box>
                    {/* </Flex> */}
                </Flex>
            </Box>
            <Box height={`calc(${defaultHeight-13}px)`}>
                <Flex
                    height="100%"
                    flexDirection="column"
                    alignItems="flex-end"
                    justifyContent="space-between"
                >
                <Box>
                    <FileStatus file={file}/>
                </Box>
                    {children}
                </Flex>

            </Box>
        </Box>
    )
}

export default FileCard