import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'

// C-CORE
import controlsDuck from './controlsDuck'
import mapDuck from './mapDuck'
import projectDuck from './projectDuck'
import imageLayerDuck from './imageLayerDuck'
import fileDuck from './fileDuck'

const reducer = combineReducers({
    map: mapDuck.reducer,
    controls: controlsDuck.reducer,
    project: projectDuck.reducer,
    imageLayer: imageLayerDuck.reducer,
    file: fileDuck.reducer
})

const store = configureStore({
    reducer: reducer,
    middleware: [],
    devTools: true,
})

window.store = store

export default store
