// React

// Reactfire / Firebase
import { 
    useFunctions 
} from 'reactfire'
import { httpsCallable } from "@firebase/functions"

// Chakra
import { DeleteIcon } from '@chakra-ui/icons'

// C-CORE
import ConfirmationDialogue from "./ConfirmationDialogue";
import { useToast } from '@chakra-ui/react';


const FileDeleteBtnContainer = ({
    file, 
    mr = 0, 
    ml = 0,
    variant = 'outline',
    size="sm",
    color="blue.400"
}) => {
    const functions = useFunctions()
    const toast = useToast()

    const toastDefaults = {
        duration: 9000,
        isClosable: true,
    }

    const deleteFile = httpsCallable(functions, 'files-deleteFile');
    // const orgId = useContext(TenantContext)
    // const firestore = useFirestore()
    // const ref = doc(firestore, 'organisations', orgId)

    const handleDelete = async () => {
        try {
            const { data } = await deleteFile({
                fileId: file.id
            })

            toast({
                ...toastDefaults,
                title: "",
                description: data.message,
                status: data.status,
            })
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <ConfirmationDialogue
            tooltipLabel="Delete this file"
            mr={mr}
            ml={ml}
            handleClose={handleDelete}
            iconComponent={<DeleteIcon />}
            btnVariant={variant}
            btnIsRound={false}
            color={color}
            btnSize={size}
            titleText='Delete File Confirmation'
            bodyText = 'Are you sure you want to delete this File? This action cannot be undone.'
            confirmText = 'Delete File'
            cancelText = 'Cancel'
        />
    )
}

export default FileDeleteBtnContainer