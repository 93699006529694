
import { addDoc } from "@firebase/firestore"
import { useToast } from "@chakra-ui/toast"
import ProjectCreateForm from "../components/ProjectCreateForm"
import ModalContainer from "./ModalContainer"
import { useDisclosure, Button } from "@chakra-ui/react"

const ProjectCreateContainer = ({collection, children}) => {
   
    const toast = useToast()
    const { isOpen, onOpen, onClose } = useDisclosure()

    const popToast = ({title, description, status}) => {
        toast({
            title: title,
            description: description,
            status: status,
            duration: 5000,
            isClosable: true,
            position: 'top-right',
        })
    }

    const createProject = (formData) => {
        addDoc(collection, {
            ...formData,
            files: [],
            keywords: formData.keywords.split(",")
        }).then(res => {
            popToast({title: "Success", description: "Project Added Successfully.", status: "success"})
        }).catch(err => {
            popToast({title: "oops!", description: "Your project couldn't be created, please try logging out and back in again. Contact support if this continues.", status: "error"})
        })
    }

    return (
        <>
        <Button size="sm" onClick={onOpen}>Create New Project</Button>
        <ModalContainer
            btnTitle = ''
            btnSize = 'sm'
            title = 'Create A New Project'
            isOpen={isOpen}
            onClose={onClose}
        >
            <ProjectCreateForm handleCreate={createProject}>
                <Button onClick={onClose} ml={2}>Close</Button>
            </ProjectCreateForm>
        </ModalContainer>
        </>
    )
}

export default ProjectCreateContainer