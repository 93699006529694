import { useContext } from "react"
import { useParams} from "react-router"
import { Link } from "react-router-dom"
import { collection, doc } from "firebase/firestore"
import { useFirestore, useFirestoreCollectionData, useFirestoreDocData } from "reactfire"

import Spinner from "../components/Spinner"
import { TenantContext } from "./TenantContainer"
import FileCard from '../components/FileCard'
import FileActionButtonContainer from "./FileActionButtonContainer"

import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, Flex, Box, VStack } from "@chakra-ui/react"
import { ChevronRightIcon } from "@chakra-ui/icons"
import ErrorMessage from '../components/ErrorMessage'
import ProjectMapContainer from "./ProjectMapContainer"

const SharedProjectFilesContainer = () => {

    const tenantId = useContext(TenantContext)
    const { projectId } = useParams()

    const firestore = useFirestore()
    const fileCollectionRef = collection(firestore, `organisations/${tenantId}/projects/${projectId}/project_files`)
    const projectRef = doc(firestore, `organisations/${tenantId}/projects/${projectId}`)

    const { status, data: project_files } = useFirestoreCollectionData(fileCollectionRef)
    const { status: projectStatus, data: project } = useFirestoreDocData(projectRef)

    if (status === 'loading' || projectStatus === 'loading') {
        return <Spinner />
    }

    return (
        <>  
            <Breadcrumb spacing='8px' separator={<ChevronRightIcon color='gray.500' />} ml="2" mb="2">
                <BreadcrumbItem>
                    <BreadcrumbLink as={Link} to='/shared-projects'>
                        My Projects
                    </BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbItem isCurrentPage>
                    <BreadcrumbLink>
                        {project.project_name}
                    </BreadcrumbLink>
                </BreadcrumbItem>
            </Breadcrumb>
            
            <Flex            
                width="100%"
                overflow="hidden"
                gap={2}
            >
                <Box
                    flexGrow={1}
                    justifyContent="left"
                    // TODO Move all this calc heights
                    height="calc(100vh - 160px)"
                    overflow="scroll"
                >
                    <VStack 
                        width="100%" 
                        align="left"
                        layerStyle="card.base"
                    >
                        {project_files?.length 
                            ? project_files.map((file, index) => (
                                <FileCard file={file} project={project} key={index}>
                                    <FileActionButtonContainer file={file} variant="outline" size="sm" del={false} />    
                                </FileCard>
                                ))
                            :   <ErrorMessage message="There are no files in this project" status="info" />
                            }
                    </VStack>
                </Box>
                <Box
                    width={{base: "25%", lg: "50%"}}
                >
                    <ProjectMapContainer />
                </Box>
            </Flex> 
        </>
    )
}

export default SharedProjectFilesContainer