import { Flex, Box, Text } from "@chakra-ui/react"
// import {  MdMap } from "react-icons/md"
import { useNavigate } from "react-router"
// import placeholder from "../images/placeholder_600x400.svg"
import formatStorageSize from "../utils/formatStorageSize"
import Thumbnail from "./Thumbnail"

const ProjectCard = ({project, link}) => {

    // project.thumbnail = placeholder

    const nav = useNavigate()
    const handleClick = (id) => {
        nav(link)        
    }
    return (
        <Box
            onClick={() => handleClick(project.id)}
            // maxW='sm'
            // TODO: Base width on breakpoints
            width="300px" 
            borderWidth='1px' 
            borderRadius='md' 
            overflow='hidden'
            m="1"
            _hover={{
                shadow: 'lg',
                cursor: 'pointer'
              }}
        >   
            <Box
                width="100%"
                height="200px"
                overflow="hidden"
            >
                <Thumbnail 
                    imagePath={project.thumbnail} 
                    boxSize="" 
                    fit="fill"
                    height="100px"
                />
            </Box>
            <Box p='4'>
                <Flex direction="column" alignItems='baseline'>
                    <Box
                        color='gray.500'
                        fontWeight='semibold'
                        letterSpacing='wide'
                        fontSize='xs'
                        mt='1'
                        width="100%"
                    >
                        <Flex
                            justifyContent="space-between"
                            // textTransform='uppercase'
                        >
                            <Box>
                                {formatStorageSize(project.project_size)}
                            </Box>
                            <Box>
                                <Text>Files: {project.files?.length}</Text>
                            </Box>
                        </Flex>
                    </Box>
                    <Box
                        mt='1'
                        fontWeight='semibold'
                        as='h4'
                        lineHeight='tight'
                        isTruncated
                    >
                        {project.project_name}
                    </Box>
                    <Box as='span' color='gray.600' fontSize='sm'>
                        {project.description}
                    </Box>
                    <Flex mt='2' align="center" width="100%">
                        {/* <Box flexGrow="1">
                            <Badge>Tag1</Badge>
                            <Badge>Tag2</Badge>
                        </Box> */}
                        <Flex width='10px' pr="4">
                            {/* <MdMap />
                            <DownloadIcon /> */}
                        </Flex>
                    </Flex>
                </Flex>
            </Box>

        </Box>
    )
}

export default ProjectCard
