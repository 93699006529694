
// Chakra
import { 
    Button,
    Flex,
    Box,

} from "@chakra-ui/react"
import { MdAdd } from "react-icons/md"
import { Modal, ModalBody, ModalContent, ModalFooter, ModalOverlay } from '@chakra-ui/modal'
import { useDisclosure } from "@chakra-ui/hooks"

// C-CORE
import AdminCreateOrganisationContainer from "./AdminCreateOrganisationContainer"
import AdminListOrganisationsContainer from "./AdminListOrganisationsContainer"
import AdminOrganisationInformationContainer from "./AdminOrganisationInformationContainer"
import CollectionContainer from "./CollectionContainer"

const AdminManagerContainer = () => {

    const { isOpen, onOpen, onClose } = useDisclosure()

    return (
        <>
            <Flex>
                {/* Project Column */}
                <Box 
                    w={{base: '100%', md: '55%'}} 
                    mr={{base: 0, md: 3}}
                    boxShadow="sm" rounded="md" bg="white"
                    p={4}
                    >
                    <Box
                        align="right"
                    >   
                        <Button 
                            colorScheme="blue"
                            onClick={onOpen} 
                            rightIcon={<MdAdd/>}
                            size="sm"
                            mb="2"
                        >
                            Create a new Organisation
                        </Button>
                    </Box>
                    {/* TODO List organisations container here - load in adjacent column */}
                    <CollectionContainer collectionPath="organisations">
                        <AdminListOrganisationsContainer />
                    </CollectionContainer>
                </Box>

                {/* Organisation details column */}
                <Box 
                    w={{base: '45%' }} 
                    display={{base: 'none', md: 'block'}} 
                    boxShadow="sm"
                    p={4}
                    rounded="md" 
                    bg="white"
                >
                    <Box align="right">

                        {/* TODO Disable organisation and all users button */}
                    </Box>
                    {/* TODO SHow organisation info container here - # of projects, storage used etc. */}
                    <AdminOrganisationInformationContainer />
                </Box>
            </Flex>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay/>
                <ModalContent>
                    <ModalBody>
                        <AdminCreateOrganisationContainer>
                            <Button mr={3} onClick={onClose} variant="outline">Close</Button>
                        </AdminCreateOrganisationContainer>
                    </ModalBody>
                    <ModalFooter>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default AdminManagerContainer