import React from 'react'
import { Outlet, useParams } from 'react-router'
import ProjectFilesContainer from './ProjectFilesContainer'

import { Flex, Box, Tabs, TabList } from '@chakra-ui/react'
import TabLink from '../components/TabLink'

const ProjectContainer = () => {
    const { projectId } = useParams()
    
    // TODO Define these in main routes object.
    const subRoutes = [
        {path: '', label: 'Project Map'},
        {path: 'users', label: 'Project Users'}
    ]

    return (
        <Flex 
            width="calc(100vw - 65px)"
            height="calc(100vh - 130px)"
            overflow="hidden"
            gap={2}
            m={1}
        >
            <Box
                layerStyle="card.base"
                flexGrow={1}
            >
                <ProjectFilesContainer projectId={projectId} />
            </Box>
            <Box 
                width={{base: "25%", lg: "50%"}}
                layerStyle="card.base"
            >
                <Box>
                    <Tabs>
                        <TabList>
                            {subRoutes.map((route, i) => <TabLink key={i} path={route.path} label={route.label} />)}
                        </TabList>
                    </Tabs>
                </Box>
                <Outlet />
            </Box>
        </Flex>
    )
}

export default ProjectContainer