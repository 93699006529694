
const inputSelectStyles = {
    variants: {
      filled: {
        field: {
          _focus: {
            borderColor: 'brand.500',
          },
        },
      },
    },
    sizes: {
      md: {
        field: {
          borderRadius: 'none',
        },
      },
    },
  };

const theme = {
    colors: {
        // This is just a test value..
        ccore: {
            100: 'green',
            selected: '#deebff'
        },

    },
    layerStyles: {
        tabChildScroll: {
            height: 'calc(100vh - 125px)',
            overflow: 'scroll'
        },

        card: {
            'base': {
                backgroundColor: 'white',
                borderRadius: '4px',
                padding: '8px'
            }
        },
        translucent: {
            'thin': {
                backdropFilter: 'blur(8px) saturate(100%) contrast(45%) brightness(100%)',
                backgroundColor: 'rgba(250, 250, 250, 0.1)',
                borderRadius: '4px'
            },
            'medium': {
                backdropFilter: 'blur(8px) saturate(100%) contrast(45%) brightness(150%)',
                backgroundColor: 'rgba(250, 250, 250, 0.6)',
                borderRadius: '4px'
            },
            'thick': {
                backdropFilter: 'blur(8px) saturate(100%) contrast(45%) brightness(290%)',
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                borderRadius: '4px'
            }
        }
    },

    components: {
        Tabs: {
            variants: {
                line: {
                        borderBottom: "5px solid red",
                        paddingTop: '200px',
                        marginTop: '200px'
                }
            },
        },

        TabList: {
            baseStyle: { 
                _focus: { 
                    boxShadow: 'none' 
                } 
            } 
        },

        Tab: {
            baseStyle: { 
                _focus: { 
                    boxShadow: 'none' 
                } 
            } 
        },

        Button: { 
            baseStyle: { 
                _focus: { 
                    boxShadow: 'none' 
                },
                borderRadius: 'sm'
            } 
        },

        Input: {...inputSelectStyles },
        Select: {...inputSelectStyles},

        CloseButton: {
            baseStyle: { 
                _focus: { 
                    boxShadow: 'none' 
                } 
            } 
        },

        IconButton: {
            baseStyle: { 
                _focus: { 
                    boxShadow: 'none' 
                } 
            } 
        },

        Box: {
            variants: {
                translucent: {
                }
            }
        }

    }
}

export default theme