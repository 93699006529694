// Chakra
import {
  Grid, 
  GridItem, 
} from '@chakra-ui/react'

// C-CORE
import TenantContainer from '../containers/TenantContainer'
import HeaderContainer from '../containers/HeaderContainer'
import MenuLeftContainer from '../containers/MenuLeftContainer'
import HomeContainer from '../containers/HomeContainer'
import ReactiveSearchContainer from '../containers/ReactiveSearchContainer'

const AdminHome = () => {
    return (
        <TenantContainer>
            <ReactiveSearchContainer>
                <Grid h="100%" w="100%" p={0} templateColumns={{base: "auto", md: "60px auto"}} overflow="hidden">
                    <MenuLeftContainer />
                    <GridItem w="100%" h="100%">
                        <HeaderContainer />
                        <HomeContainer />
                    </GridItem>
                </Grid>
            </ReactiveSearchContainer>
        </TenantContainer>
    )
}

export default AdminHome