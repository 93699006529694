// React
import React from 'react'
import { useContext } from 'react'

// Chakra
import { VStack, Flex, useToast, Box } from "@chakra-ui/react"

// Firebase / reactfire
import { useFirestore, useFirestoreCollectionData, useFirestoreDocData } from 'reactfire'
import { doc, collection, writeBatch } from 'firebase/firestore'

// C-CORE
import { TenantContext } from './TenantContainer'
import Spinner from '../components/Spinner'
import ProjectAddItems from './ProjectAddItems'
import FileCard from '../components/FileCard'
import FileActionButtonContainer from './FileActionButtonContainer'
import FilesEmpty from '../components/FilesEmpty'
import { Link } from 'react-router-dom'

import { Breadcrumb, BreadcrumbLink, BreadcrumbItem } from '@chakra-ui/react'
import { ChevronRightIcon } from '@chakra-ui/icons'

const ProjectFilesContainer = ({
    projectId
}) => {
    const toast = useToast()
    const orgId = useContext(TenantContext)
    const firestore = useFirestore()
    const projectRef = doc(firestore, `organisations/${orgId}/projects/${projectId}`)
    const {status: projectStatus, data: project} = useFirestoreDocData(projectRef, {idField: 'id'})

    const projFileRef = collection(firestore, `organisations/${orgId}/projects/${projectId}/project_files`)
    const {status: fileStatus, data: projectFileColl} =  useFirestoreCollectionData(projFileRef, { idField: 'id'})

    const filesRef = collection(firestore, `organisations/${orgId}/files`)
    const { status: allFileStatus, data: allFiles } = useFirestoreCollectionData(filesRef, {idField: 'id'})

    
    const fileList = projectFileColl || []

    const unUsedFiles = () => allFiles.filter(i => !projectFileColl.find(f => f.id === i.id))
    
    if (fileStatus !== 'success' || projectStatus !== 'success' || allFileStatus !== 'success') {
        return <Spinner />
    }

    const handleAddFile = async (files) => {

        try {
            const batch = writeBatch(firestore)

            for (const file of files) {
                batch.set(doc(projectRef, "project_files", file.id), file)
            }

            // Commit the batch
            await batch.commit();

            toast({
                title: "File Added",
                description: `${files.length} files added to the project`,
                status: "success",
                duration: 9000,
                isClosable: true,
            })

        } catch (error) {
            toast({
                title: "Opps!",
                description: "File could not be added to the project",
                status: "warning",
                duration: 9000,
                isClosable: true,
            })
        }
    }

    return (
        <>
            <Box 
                layerStyle="card.base"
                overflow="hidden"
            >
                <Flex
                    direction="row"
                    mb={2}
                    justifyContent="space-between"
                >

                    <Breadcrumb spacing='8px' separator={<ChevronRightIcon color='gray.500' />} ml="2" mb="2">
                        <BreadcrumbItem>
                            <BreadcrumbLink as={Link} to='/projects'>
                                Projects
                            </BreadcrumbLink>
                        </BreadcrumbItem>
                        <BreadcrumbItem isCurrentPage>
                            <BreadcrumbLink>
                                {project.project_name}
                            </BreadcrumbLink>
                        </BreadcrumbItem>
                    </Breadcrumb>

                        <ProjectAddItems 
                            items={unUsedFiles()} 
                            title="Add files to project"
                            handleAddAction={handleAddFile}
                            displayKey='file_label'
                            />
                </Flex>
                <Box 
                    flexGrow={1}
                    // TODO Move all this calc heights
                    height="calc(100vh - 200px)"
                    overflow="scroll"
                >
                    <VStack width="100%" align="left">
                        {fileList?.length > 0
                            ? fileList.map((file) => {
                                return (
                                    <FileCard 
                                    key={file.id} 
                                    file={file}
                                    collectionRef={projFileRef}
                                    canEdit={true}
                                    >
                                        <FileActionButtonContainer 
                                            file={file}
                                            project={project}
                                            variant="outline" 
                                            size="sm" 
                                            canEdit={true}
                                            removeFromProject={true}
                                            del={false}
                                            />
                                    </FileCard>
                                )
                            })
                            
                            : <FilesEmpty />
                        }
                    </VStack>
                </Box>
            </Box>
        </>
    )
}

export default ProjectFilesContainer
