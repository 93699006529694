import { useSigninCheck } from 'reactfire'
import Spinner from '../components/Spinner'
import { Navigate } from 'react-router'
import ErrorUnauthorised from './ErrorUnauthorised'

const AuthCheck = ({
    admin = false, 
    superAdmin = false, 
    children
}) => {

    const { status, data } = useSigninCheck({
        validateCustomClaims: (userClaims) => {
            // TODO: Check against list of valid orgs? might be slow - this isn't enough
            // TODO: This needs testing
            
            // Must have an organisation
            if (!userClaims.organisation) {
                return {
                    hasRequiredClaims: false
                }
            }

            // if superAdmin specified:
            if (superAdmin) {
                return {
                    hasRequiredClaims: !!userClaims.superAdmin && !!userClaims.organisation
                }
            }

            // if admin specified:
            if (admin) {
                return {
                    hasRequiredClaims: !!userClaims.admin 
                }
            }
        }
    })

    if (status !== 'success') return <Spinner />

    // Must be logged in:
    if (!data.signedIn) return <Navigate to="/login" replace />

    // check claims above:
    if (!data.hasRequiredClaims) return <ErrorUnauthorised />

    return children
}

export default AuthCheck