
import { addDoc, where, query, getDocs} from "@firebase/firestore"
import { useToast } from "@chakra-ui/toast"
import UserCreateForm from "../components/UserCreateForm"

const UserCreateContainer = ({collection, children}) => {
   
    const toast = useToast()

    const popToast = ({title, description, status}) => {
        toast({
            title: title,
            description: description,
            status: status,
            duration: 5000,
            isClosable: true,
            position: 'top-right',
        })
    }

    async function userExists(email) {
        const q = query(collection, where("email", "==", email))
        const docs = await getDocs(q)
        return docs.empty ? false : true
    }

    const createUser = async (formData) => {

        if (await userExists(formData.email)) {
            popToast({title: 'Error', status: 'error', description: 'User already exists'})
            return
        }

        addDoc(collection, {
            ...formData
        }).then(res => {
            popToast({title: "Success", description: "User Added Successfully.", status: "success"})
        }).catch(err => {
            popToast({title: "oops!", description: "The user could not be created. Contact support if this continues.", status: "error"})
        })
    }

    return (
        <UserCreateForm handleCreate={createUser}>
            {children}
        </UserCreateForm>
    )
}

export default UserCreateContainer