import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    uri: false
}

function setPreviewFile (state, action) {
    return {
        ...state,
        file: action.payload
    }
}

function setUri (state, action) {
    return {
        ...state,
        uri: action.payload
    }
}

function clearUri (state, action) {
    return {
        ...state,
        uri: false
    }
}

const map = createSlice({
    name: 'imageLayer',
    initialState,
    reducers: {
        setPreviewFile,
        setUri,
        clearUri
    }
  })
  
  export default map