import { useShiftSelected } from '../hooks/useShiftSelected';
import { useSelected } from '../hooks/useSelected';
import { 
    Box,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    useDisclosure,
    Checkbox,
    Stack,
    Text
} from "@chakra-ui/react"

const ProjectAddFromList = ({
    handleAddAction,
    title,
    projectId,
    existingItems,
    items,
    displayKey,
}) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    // TODO: pass in currently selected files or remove from collection entirly
    const { selected, change } = useSelected([])
    const onChange = useShiftSelected(existingItems, change)

    const addButtonIsDisabled = () => {
        return !items.length || !selected.length
    }

    return (
      <>
        <Button onClick={onOpen} size="sm">{title}</Button>
        <Modal 
            isOpen={isOpen} 
            onClose={onClose}
            size="xl"
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{title}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                <Box>
                    <Stack
                        direction="column"
                    >
                        {items?.length 
                            ?   items.map(item => (
                                    <Box
                                        key={item.id}
                                        px={2}
                                        py={1}
                                        backgroundColor={
                                            selected?.includes(item)
                                                ? 'gray.200'
                                                : 'white'
                                        }
                                        overflow="hidden"
                                    >
                                        <Checkbox
                                            isChecked={selected?.includes(item)}
                                            onChange={(event) => onChange(event, item)}
                                            id={item.id}
                                            size='lg'
                                        >
                                                {item[displayKey]}
                                        </Checkbox>
                                    </Box>))
                            :   <Text>No more items to add</Text>
                        }
                    </Stack>
                </Box>
            </ModalBody>
            <ModalFooter>
                <Button 
                    colorScheme="blue" 
                    mr={3} 
                    onClick={onClose} 
                    size="sm"
                >
                    Close
                </Button>
                <Button
                    isDisabled={addButtonIsDisabled()}
                    size="sm"
                    onClick={() => handleAddAction(selected)}
                >
                    Add To Project
                </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        </>
    )
}

export default ProjectAddFromList