import { useContext } from 'react'

// Firebase Reactfire
import { useFirestore, useUser } from 'reactfire'
import { collection, addDoc, doc, Timestamp } from '@firebase/firestore'
import { useToast } from '@chakra-ui/react'

// C-CORE
import { TenantContext } from './TenantContainer'
import UploadComponent from '../components/UploadComponent'

const UploadContainer = () => {

    const firestore = useFirestore()
    const user = useUser()
    const orgId = useContext(TenantContext)
    const popToast = useToast()
    
    const addItem = async (file) => {
        const fileRef = doc(firestore, 'organisations', orgId)
        const fileCollection = collection(fileRef, 'files')

        try {
            await addDoc(fileCollection,
                {
                    user: user.data.uid,
                    storage_key: file.name,
                    description: 'please add a description',
                    keywords: ["Add", "keywords"],
                    size: file.size,
                    file_label: file.name,
                    status: 'processing',
                    created_at: Timestamp.fromDate(new Date())
                }
            )
        } catch (e) {
            // TODO log error
            popToast("Error uploading file", 'Your file could not be uploaded due to a database error', 'error')
        }
    }

    return (
        <>
            <UploadComponent onUploadComplete={addItem} />
        </>
    )
}

export default UploadContainer
