import {Text} from '@chakra-ui/react'

import { useFirestore, useFirestoreCollectionData } from "reactfire"
import { collection, query, where } from '@firebase/firestore' 

import Spinner from "../components/Spinner"

const UserProjectsContainer = ({user}) => {
    const firestore = useFirestore()
    const projects = collection(firestore, `organisations/${user.organisation_id}/projects`)
    const projectQuery = query(projects, where('__name__', 'in', user.projects))
    const {status, data: projectData} = useFirestoreCollectionData(projectQuery, {
        idField: 'id'
    })
    
    if (!status === 'success') {
        return <Spinner />
    }

    return (
        projectData 
            ? projectData.map((project, index) => <Text key={index}>{project.project_name}</Text>)
            : <Text>The user doesn't have access to any projects</Text>
    )
}   


export default UserProjectsContainer