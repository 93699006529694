import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { FormControl, FormLabel } from "@chakra-ui/form-control"
import { Input, Button, Box, Text, VStack } from "@chakra-ui/react"

const ProjectAddForm = ({
    handleCreate,
    children
}) => {
    const { register, handleSubmit, formState: { errors, isSubmitSuccessful }, reset } = useForm();
    
    useEffect(() => {
        if (isSubmitSuccessful) {
          reset();
        }
      }, [isSubmitSuccessful, reset])

    return (
        <Box>
            <form onSubmit={handleSubmit(handleCreate)}>
                <VStack pt={4}>
                    <Text>Create a new project:</Text>
                    <FormControl id="project_name" isRequired>
                        <FormLabel>Project Name:</FormLabel>
                        <Input 
                            {...register("project_name", {
                                required: "Please enter a project name of at least 5 characters",
                                minLength: 5,
                                maxLength: 80
                            })}
                            placeholder="Project Name"
                            />
                        {errors.project_name && 
                            <Text color="red" size="xs">{errors.project_name.message}</Text>
                        }
                    </FormControl>
                    <FormControl id="description" isRequired>
                        <FormLabel>Description:</FormLabel>
                        <Input 
                            {...register("description", {
                                required: "Please enter a description for the project of at least 10 characters",
                                minLength: 1,
                                maxLength: 512
                            })}
                            placeholder="Description"/>
                                            {errors.description && 
                            <Text color="red" size="xs">{errors.description.message}</Text>
                        }
                    </FormControl>
                    <FormControl id="date" isRequired>
                        <FormLabel>Project Date</FormLabel>
                        <Input 
                            type="date"
                            {...register("project_date", {
                                required: "Please select a date",
                            })}
                        />
                    </FormControl>
                    <FormControl id="keywords" isRequired>
                        <FormLabel>Tags:</FormLabel>
                        <Input 
                        placeholder="ortho, forest, matrice"
                        {...register("keywords", {
                            required: "Please add at least one tag, this helps the search!",
                            minLength: 3,
                            maxLength: 80
                            })}
                        />
                        {errors.keywords && 
                            <Text color="red" size="xs">{errors.keywords.message}</Text>
                        }
                    </FormControl>
                    <FormControl id="customer_ref" isRequired>
                        <FormLabel>Customer name or reference #:</FormLabel>
                        <Input 
                        {...register("customer_ref", {
                            required: "Please enter a meaningful client identifier",
                            minLength: 3,
                            maxLength: 80
                            })}
                        placeholder="Customer x / P123432"/>
                        {errors.customer_ref && 
                            <Text color="red" size="xs">{errors.customer_ref.message}</Text>
                        }
                    </FormControl>
                    <Box width="100%" align="right">
                        <Button type="submit" colorScheme="blue" my={2} size="md">Create</Button>
                        {/* Pass additional buttons */}
                        {children}
                    </Box>
                </VStack>
            </form>
        </Box>
    )
}

export default ProjectAddForm