// React
import { useContext } from 'react'

// Reactfire / Firebase
import { useFirestore } from 'reactfire'
import { doc, deleteDoc} from 'firebase/firestore'

// Chakra
import { DeleteIcon } from '@chakra-ui/icons'
import { useToast } from '@chakra-ui/toast';

// C-CORE
import { TenantContext } from './TenantContainer';
import ConfirmationDialogue from "../components/ConfirmationDialogue";

const UserDeleteBtnContainer = ({
    userId, 
    mr = 0, 
    ml = 0,
    variant = 'solid',
    size="sm",
    color="red",
    disabled=false
}) => {

    const orgId = useContext(TenantContext)
    const firestore = useFirestore()
    const ref = doc(firestore, 'organisations', orgId)
    const toast = useToast()

    const handleDelete = async () => {
        await deleteDoc(doc(ref, 'org_users', userId))
        toast({

        })
    }

    return (
        <ConfirmationDialogue
            tooltipLabel="Delete this user"
            mr={mr}
            ml={ml}
            handleClose={handleDelete}
            iconComponent={<DeleteIcon />}
            btnVariant={variant}
            btnIsRound={false}
            color={color}
            btnSize={size}
            titleText='Confirm delete user'
            bodyText = 'Are you sure you want to delete this user? They will lose access to all projects. This action cannot be undone.'
            confirmText = 'Delete User'
            cancelText = 'Cancel'
            disabled={disabled}
        />
    )
}

export default UserDeleteBtnContainer