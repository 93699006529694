import React, { useState } from 'react'
import { sendPasswordResetEmail } from '@firebase/auth'
import { useAuth } from 'reactfire'
import { FormHelperText, Text, useDisclosure, Modal, ModalOverlay, ModalContent, ModalBody, ModalFooter, Button, Input, FormControl, FormLabel } from '@chakra-ui/react'

const UserPasswordRestContainer = () => {
    const auth = useAuth()

    const { isOpen, onOpen, onClose } = useDisclosure()
    const [email, setEmail] = useState('')
    const [submitted, setSubmitted] = useState(false)

    const handlePasswordReset = async () => {
        setSubmitted(true)
        await sendPasswordResetEmail(auth, email)
    }

    return (
        <>
            <Modal
                isCentered
                onClose={onClose}
                isOpen={isOpen}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalBody>
                        {
                            !submitted &&
                                <FormControl>
                                    <FormLabel>Please enter your email:</FormLabel>
                                    <Input onChange={(event) => setEmail(event.target.value)}/>
                                </FormControl>
                        }
                        {
                            submitted &&
                                <Text m={2}>Thank you, an email has been sent to the given address</Text>
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button variant='ghost' size="sm" mr={3} onClick={onClose}>
                        Close
                        </Button>
                        { !submitted && 
                            <Button colorScheme='blue'  size="sm" onClick={handlePasswordReset}>Reset Password</Button>
                        }
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <FormHelperText>
                <Button size="sm" variant="ghost" onClick={onOpen}>Forgot password</Button>
            </FormHelperText>
        </>
    )
}

export default UserPasswordRestContainer