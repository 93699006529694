const baseUrl = process.env.REACT_APP_TILER_URL

export const getTileJson = (file) => {
    const uri = file.assets?.cog?.uri
    const baseUri = `${baseUrl}/cog/tilejson.json?tile_scale=1&TileMatrixSetId=WebMercatorQuad&url=${uri}`

    const bidx = file.properties['tile:bidx'] || []
    const rescale = file.properties['tile:rescale'] || []

    const bdixString = bidx.length ? `&bidx=${bidx.join('&bidx=')}` : ''
    const scaleStr = rescale.length ? `&rescale=${rescale.toString()}` : ''
    return fetch(baseUri + bdixString + scaleStr)
}