import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    editFile: null,
}

function editFile (state, action) {
    const { payload } = action
    return {
        ...state,
        editFile: payload
    }
}

const file = createSlice({
    name: 'file',
    initialState,
    reducers: {
        editFile,
    },
})

export default file