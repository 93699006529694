import React from 'react'
import { Link } from 'react-router-dom'
import { Tab } from '@chakra-ui/react'
import { useMatch, useResolvedPath } from 'react-router'

const TabLink = ({path, label}) => {
    const resolved = useResolvedPath(path)
    const match = useMatch({path: resolved.pathname, end:true})
    
    // TODO: Use theme constant
    const color = match ? 'blue.600' : ''
    
    return (
        <Link to={path}>
            <Tab _selected={{color}} borderBottomColor={color} color={color}>{label}</Tab>
        </Link>
    )
}

export default TabLink