import { Center } from '@chakra-ui/react'
import { SettingsIcon } from '@chakra-ui/icons'

const SettingsMenuItem = ({ routerLocation = '/' }) => {
    return (
        <Center w='60px' h='60px'>
            <SettingsIcon color='white' />
        </Center>
    )
}

export default SettingsMenuItem
