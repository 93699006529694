// React
import React from "react";
import { useContext } from "react";

// Chakra
import {
    VStack,
    Grid,
    GridItem,
    Text,
    Divider,
    useToast,
    Box,
    Flex,
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";

// Firebase / reactfire
import {
    useFirestore,
    useFirestoreCollectionData,
    useFirestoreDocData,
} from "reactfire";
import { doc, updateDoc, collection } from "firebase/firestore";

// C-CORE
import { TenantContext } from "./TenantContainer";
import Spinner from "../components/Spinner";
import ProjectUserItem from "../components/ProjectUserItem";
import ConfirmationDialogue from "../components/ConfirmationDialogue";
import { useParams } from "react-router";
import ProjectAddFromList from "./ProjectAddItems";
import arrayDiff from "../utils/arrayDiff";

const ProjectUsersContainer = () => {
    const { projectId } = useParams();

    const toast = useToast();
    const orgId = useContext(TenantContext);
    const firestore = useFirestore();

    const ref = doc(firestore, `organisations/${orgId}/projects/${projectId}`);
    const { status, data: projectDoc } = useFirestoreDocData(ref);

    const orgUsersRef = collection(
        firestore,
        `organisations/${orgId}/org_users`
    );
    
    const { status: orgUserStatus, data: orgUsers } =
        useFirestoreCollectionData(orgUsersRef, { idField: "id" });

    if (status !== "success" || orgUserStatus !== "success") {
        return <Spinner />;
    }

    const unUsedUsers = () => arrayDiff(orgUsers, projectDoc.project_users)
    
    const handleRemoveUser = async (id) => {
        const userArray = projectDoc.project_users;
        const newUsersArray = userArray.filter((user) => user.id !== id);
        await updateDoc(ref, { project_users: newUsersArray });
        toast({
            title: "User Removed",
            description: "User removed from the project",
            status: "success",
            duration: 9000,
            isClosable: true,
        });
    };

    const handleAddUser = async (user) => {
        const userArray = projectDoc.project_users
            ? projectDoc.project_users
            : [];

        console.log('userArray:', userArray)

        const addUser = async (fieldData) => {
            await updateDoc(ref, { project_users: fieldData });
            toast({
                title: "User Added",
                description: `${user.name} added to the project`,
                status: "success",
                duration: 9000,
                isClosable: true,
            });
        };

        if (userArray.length === 0) {
            addUser(user);
            return;
        }

        if (!userArray.some((existing) => existing.id === user.id)) {
            userArray.push(...user);
            addUser(userArray);
            return;
        }

        toast({
            title: "Opps!",
            description: "User already exists in this project",
            status: "warning",
            duration: 9000,
            isClosable: true,
        });
    };

    return (
        <>
            <Box p={2}>
                <Flex justify="right">
                    <ProjectAddFromList
                        title="Add Users to project"
                        items={unUsedUsers()}
                        handleAddAction={handleAddUser}
                        displayKey="name"
                    />
                </Flex>
                <VStack width="100%" align="left">
                    <Grid
                        templateColumns="repeat(7, 1fr)"
                        gap={1}
                        color="gray.600"
                        fontSize="sm"
                    >
                        <GridItem colSpan={{base: 5, lg: 2}}>
                            <Text>Name</Text>
                        </GridItem>
                        <GridItem colSpan={2} display={{base: 'none', lg: 'block'}}>
                            <Text>Company</Text>
                        </GridItem>
                        <GridItem colSpan={2} display={{base: 'none', lg: 'block'}}>
                            <Text>Email</Text>
                        </GridItem>
                        <GridItem colStart={7} colEnd={8}></GridItem>
                    </Grid>
                    <Divider />
                    {projectDoc.project_users?.length > 0 &&
                        projectDoc.project_users.map((user, index) => (
                            <ProjectUserItem key={index} user={user}>
                                <ConfirmationDialogue
                                    tooltipLabel="Remove user access to project"
                                    mr="1"
                                    ml="1"
                                    handleClose={() =>
                                        handleRemoveUser(user.id)
                                    }
                                    iconComponent={<CloseIcon />}
                                    btnVariant="ghost"
                                    btnIsRound={false}
                                    color="red.300"
                                    btnSize="xs"
                                    titleText="Confirm user removal"
                                    bodyText={`Remove ${user.name} from project?`}
                                    confirmText="Remove"
                                    cancelText="Cancel"
                                />
                            </ProjectUserItem>
                        ))}
                </VStack>
            </Box>
        </>
    );
};

export default ProjectUsersContainer;
